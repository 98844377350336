var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticClass: "page-user"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('User')) + " " + _vm._s(_vm.user.lastname) + " ")]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'UserLoginHistory',
        params: {
          userUUID: `${_vm.$route.params.userUUID}`
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-history ")]), _vm._v(" " + _vm._s(_vm.$t('LoginHistory')) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.globalErrorMessage ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.globalErrorMessage) + " ")]) : _vm._e()], 1), _vm.user ? _c('UserProfile', {
    attrs: {
      "user-u-u-i-d": _vm.user._id
    }
  }) : _vm._e(), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('AdminAccess')))]), _c('v-card-text', [_vm.rolesAccessArray.length > 0 ? [_c('h6', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('UserHasAccessTo')) + " ")]), _vm._l(_vm.rolesAccessArray, function (roles, index) {
    return _c('ul', {
      key: index
    }, [_c('li', [_vm._v(_vm._s(roles))])]);
  })] : [_c('h6', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('NoAccess')) + " ")])]], 2), _c('v-card-actions', [_c('v-col', [_c('v-divider', {
    staticClass: "mb-5"
  }), _vm.rolesAccessArray.length > 0 ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'SystemAdminUsers'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-key ")]), _vm._v(" " + _vm._s(_vm.$t('ManageAdminSecurityAccess')) + " ")], 1) : this.invitation ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.inviteAdminUser
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Invite')) + " ")], 1) : _c('strong', [_vm._v(_vm._s(_vm.$t('CantBeInvited')))])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }