<template lang="html" src="./pageUser.template.vue"></template>

<style lang="scss" src="./pageUser.scss"></style>

<script>
import { UserProfile, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUser.i18n');

export default {
  name: 'PageUser',
  components: { UserProfile },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      user: null,
      rolesAccessArray: [],
      globalErrorMessage: null,
      invitation: true,
    };
  },
  created() {
    /**
     * GET USER
     */
    this.getUser();
    //
    // GET USER ADMIN ROLES
    //
    this.getUserAdminRoles();
  },
  methods: {
    /**
     * GET USER
     */
    getUser() {
      this.$apiInstance.getUser(this.$route.params.userUUID).then(
        (data) => {
          this.user = data;
          this.checkAdminUserEmail();
        },
        (error) => {
          /**
           * ERROR GET USER
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    getUserAdminRoles() {
      /**
       * GET USER ADMIN ROLES
       */
      this.$apiInstance.getAdminUserRoles(this.$route.params.userUUID).then(
        (accessData) => {
          this.rolesAccessArray = accessData;
        },
        (error) => {
          /**
           * ERROR GET USER ADMIN ROLES
           */
          this.globalErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
    checkAdminUserEmail() {
      const regex = /^[A-Za-z.]+[A-Za-z]+@cm-technology.io$/i;
      if (!regex.test(this.user.email)) {
        this.invitation = false;
      }
    },
    inviteAdminUser() {
      /**
       * GET INVITE ADMIN USER
       */
      const inviteAdminUserBody = new this.$BcmModel.InviteAdminUserBody(
        this.user.email
      );
      this.$apiInstance.inviteAdminUser(inviteAdminUserBody).then(
        (emailData) => {
          this.user.email = emailData;
          /**
           * Component BeelseNotifications used
           */
          this.$notification.notify('SUCCESS',this.$t('UserSuccessfullyInvited'));
          this.getUserAdminRoles();
        },
        (error) => {
          /**
           * ERROR INVITE ADMIN USER
           */
          this.globalErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>
